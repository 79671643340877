<template>
    <div class="more">
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="今日必读"
                z-index="99"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="height-list">
            <van-tabs
                    v-model="pageList.type"
                    class="more"
                    animated
                    swipeable
                    :border="false"
                    title-active-color="#4D77FD"
                    title-inactive-color="#333333"
                    @change="changeClick"
            >
                <template v-for="item in Tabs">
                    <van-tab :title="item">
                        <div class="wel" v-if="loading_show">
                            <van-loading size="20px">加载中...</van-loading>
                        </div>
                        <div class="no" v-if="moreList.length== 0 && !loading_show">
                            <No title="暂无书籍"></No>
                        </div>
                        <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    :finished="finished"
                                    :immediate-check="false"
                                    :finished-text="finished_text"
                                    @load="onLoad"
                            >
                                <div class="div">
                                    <div class="book">
                                        <template v-for="item in moreList">
                                            <div class="book-top-list" @click="detailsClick(item)">
                                                <p class="points_relative">
                                                    <img :src="item.coverpic" alt="" class="book-img">
                                                    <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                                                </p>
                                                <div>
                                                    <div class="book-title">
                                                        <p class="book-title-left">{{item.title}}</p>
                                                        <p class="book-title-right" v-if="item.average_score != 0">
                                                            {{item.average_score}} <span
                                                                class="book-title-right1">分</span>
                                                        </p>
                                                    </div>
                                                    <div class="book-subtitle">
                                                        {{item.desc}}
                                                    </div>
                                                    <div class="author-list">
                                                        <div class="author-icon-left">
                                                            <p><img src="../../assets/images/author_icon.png" alt=""
                                                                    class="author_icon"></p>
                                                            <p>{{item.author}}</p>
                                                        </div>
                                                        <div class="author-icon-left">
                                                            <p class="author-text">{{item.classify}}</p>
                                                            <p class="author-subtitle" v-if="item.iswz == 1">连载中</p>
                                                            <p class="author-subtitle" v-else>已完结</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                    </van-tab>
                </template>
            </van-tabs>
        </div>
    </div>
</template>

<script>
    import No from '../../components/No'

    export default {
        name: "More",
        components: {
            No
        },
        data() {
            return {
                Tabs: ['新书', '连载中', '完结',],
                loading_show: true,
                refreshing: false,
                loading: false,
                finished: false,
                finished_text: '',
                pageList: {
                    column_id: '',
                    type: 0,
                    page: 1,
                    page_size: 10,
                },
                moreList: [],
                moreList_show: false,
                count: '',
            }
        },
        created() {
            this.pageList.column_id = this.$route.query.id
            this.get_anime_by_channel()
        },
        methods: {
            get_anime_by_channel() {
                this.$axios.get('/api/bookstores/get_anime_by_channel', {
                    params: this.pageList
                })
                    .then(res => {
                        this.loading_show = false
                        var res = res.data.data
                        this.refreshing = false
                        this.loading = false;
                        if (this.moreList_show) {
                            this.moreList = res.list
                            this.moreList_show = false
                        } else {
                            this.moreList = this.moreList.concat(res.list)
                        }

                        this.count = res.count
                        if (this.moreList.length >= this.count) {
                            this.finished = true
                            this.finished_text = '没有更多了'
                        } else {
                            this.finished = false
                        }
                        if (this.pageList.page == 1) {
                            this.finished_text = ''
                        }

                    })
            },
            changeClick() {
                this.loading_show = true
                this.moreList = []
                this.pageList.page = 1
                this.moreList_show = true
                this.get_anime_by_channel()
            },
            onRefresh() {
                //下拉刷新
                this.pageList.page = 1
                if (this.moreList.length >= this.count) {
                    this.finished = true
                } else {
                    this.finished = false
                }
                this.moreList_show = true
                this.get_anime_by_channel()
            },
            onLoad() {
                if (this.finished) return
                this.pageList.page++;
                this.get_anime_by_channel();
            },
            detailsClick(item) {
                this.$router.push({
                    path: '/details?id=' + item.id
                })
            },
        }
    }
</script>

<style scoped>

    .book {
        margin-bottom: 50px;
        /*min-height: calc(100vh - 320px);*/
        /*min-height: 100vh;*/
        /*overflow: auto;*/
    }

    .author-text {
        color: #F5325E;
        font-size: 20px;
        padding: 5px 15px;
        border: 1px solid #F5325E;
        border-radius: 4px;
        margin-right: 10px;
    }

    .author-subtitle {
        color: #BFC2CC;
        font-size: 20px;
        padding: 5px 15px;
        border: 1px solid #EDEFF2;
        border-radius: 4px;
    }

    .author-icon-left {
        display: flex;
        align-items: center;
        font-size: 24px;
        color: #BFC2CC;
    }

    .author-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .author_icon {
        width: 20px;
        height: 23px;
        margin-right: 10px;
    }

    .book-subtitle {
        font-size: 24px;
        font-weight: 400;
        color: rgba(142, 124, 105, 1);
        line-height: 36px;
        background: rgba(255, 253, 249, 1);
        opacity: 0.7;
        border-radius: 10px;
        height: 65px;
        width: 467px;
        margin: 30px 0 42px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .book-top-list {
        display: flex;
        align-items: center;
        margin-top: 40px;
        min-height: 200px;
    }

    .book-title-right {
        font-size: 36px;
        color: #FCAD0F;
    }

    .book-title-right1 {
        font-size: 30px;
    }

    .book-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 467px;
    }

    .book-title-left {
        font-size: 30px;
        color: #303133;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .book-img {
        width: 170px;
        height: 238px;
        border-radius: 10px;
        margin-right: 24px;
        display: inline-block;
        background-size: cover;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
    }
</style>